<template>
  <BlokkliProvider v-bind="pbProps">
    <BlokkliField name="field_paragraphs" :list="paragraphs" />
    <slot></slot>
  </BlokkliProvider>
</template>

<script lang="ts" setup>
import { GroupB2bFragment } from '#graphql-operations'

const props = defineProps<{
  title?: string
  nid?: string
  uuid: string
  canEdit?: boolean
  subtitle?: string
  body?: string
  paragraphs?: GroupB2bFragment['paragraphs']
}>()

const pbProps = buildParagraphsBuilderProps(props, 'b2b', 'group')
</script>
