<template>
  <template v-if="result && result.entity">
    <NodePage v-if="'hero' in result.entity" v-bind="result.entity" />
    <NodeBlogArticle
      v-else-if="'tagsField' in result.entity"
      v-bind="result.entity"
    />
    <GroupGroupB2b v-else v-bind="result.entity" />
  </template>
</template>

<script lang="ts">
export default {
  name: 'PageRoute',
}
</script>

<script lang="ts" setup>
import {
  GroupFragment,
  NodeBlogArticleFragment,
  NodePageFragment,
} from '#graphql-operations'

definePageMeta({
  name: 'drupal-route',
  path: '/:slug(.*)*',
  languageMapping: {
    en: '/:slug(.*)*',
    de: '/de/:slug(.*)*',
    fr: '/fr/:slug(.*)*',
  },
  drupalRoute: true,
})

const nuxtRoute = useRoute()

const { data: query } = await useAsyncData(nuxtRoute.path, async () =>
  useGraphqlQuery('route', {
    path: nuxtRoute.path,
  }).then((v) => v.data),
)
defineSchemaOrg(query)

const result = await useDrupalRoute<
  NodePageFragment | NodeBlogArticleFragment | GroupFragment
>(query.value)

setLanguageLinksFromRoute(query.value)
useRouteCache((c) => c.setCacheable().setMaxAge(600))
</script>
