<template>
  <div class="aspect-[16/9]">
    <video v-if="url" controls autoplay loop muted>
      <source :src="url" type="video/mp4" />
    </video>
  </div>
</template>

<script lang="ts" setup>
import { MediaVideoFragment } from '#graphql-operations'

const props = defineProps<{
  video?: MediaVideoFragment['video']
}>()

const url = computed(() => props.video?.entity?.url?.path)
</script>
